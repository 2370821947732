<template>
  <!-- 查看退货单 -->
  <div class="FormWrap">
    <mia-navback @back="back" :isShowSave="false" />
    <div
      v-loading="!DetailData"
      v-if="DetailData"
      class="content"
      style="padding: 29px 20px"
    >
      <el-form
        ref="searchForm"
        size="mini"
        :model="DetailData"
        :inline="true"
        :rules="searchRules"
        label-position="left"
      >
        <el-form-item label="供应商" prop="supplierId">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.supplierName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="入库仓库" prop="wmsId">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.wmsName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="经手人">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.handlerName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="录单时间" prop="entryDate">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.entryDate"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="单号">
          <el-input
            style="width: 100%"
            disabled
            v-model="DetailData.purchaseRefundNo"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <SalesreturnTable
        ref="Editable"
        :isPutStorage="false"
        :wmsId="1"
        :examineForm="DetailData"
        :tableData="tableData"
        :examine="true"
      />
    </div>
  </div>
</template>
<script>
import { GetPurchaseRefundInfo } from "@/api/purchaseRefund";
import { SalesreturnGoods,SalesreturnBatchGoods,SalesreturnSerialGoods } from "@/until/statement.js";
import { backUnit } from "@/until/auth.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      DetailData: null,
      tableData: [],
      searchRules: {
        supplierId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        wmsId: [
          { required: true, message: "请选择入库仓库", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.GetInfo(this.data.id);
  },
  watch: {
    data(val) {
      this.data = val;
    },
    tabeData(val) {
      this.tabeData = val;
    },
  },
  methods: {
    GetInfo(id) {
      GetPurchaseRefundInfo(id).then((res) => {
        if (res.code === 1) {
          this.DetailData = res.data;
          this.tableData = [];
          this.SetupTable(this.DetailData.list);
        }
      });
    },
    SetupTable(list) {
      const tabeData = JSON.parse(JSON.stringify(list));
      tabeData.forEach((v) => {
        let obj = null;
        // if(!v.isBatch && !v.isSerial) {
          // 普通商品
          obj = {... new SalesreturnGoods(v.id,v.productName,v.productId,v.productCode,'普通', v.unit, v.unitId,v.unitList,v.unitValue,v.thisInventoryNum,null,v.num,v.price,v.totalPrice)}
        // }
      //   if(v.isBatch && !v.isSerial) {
      //     obj = {... new SalesreturnBatchGoods(v.id,v.productName,v.productId,v.productCode,'批次',v.batchNo,v.productionDate,v.expire,v.expireDate,v.unit, v.unitId,v.unitList,v.unitValue,v.thisInventoryNum,null,v.num,v.price,v.totalPrice)}
      //   }
      //   if(!v.isBatch && v.isSerial) {
      //     // 构建(单)序列码商品
      //       obj = { ...new SalesreturnSerialGoods(v.id,v.productName,v.productId,v.productCode,"序列码",v.unit, v.unitId,v.unitList,v.unitValue,v.thisInventoryNum,null,
      //       v.serialNoList,v.num,v.price,v.totalPrice)};
      //   }
      //   // 构建批次序列码商品
      //   if(v.isBatch && v.isSerial) {
      //    obj = {... new SalesreturnBatchGoods(v.id,v.productName,v.productId,v.productCode,'批次序列码',v.batchNo,v.productionDate,v.expire,v.expireDate,v.unit, v.unitId,v.unitList,v.unitValue,v.thisInventoryNum,null,v.num,v.price,v.totalPrice)}
      //    obj.serialNoList = v.serialNoList
      //    obj.isBatch = 1
      //    obj.isSerial = 1
      //  }
        // 设置辅助单位换算
        obj.unitConversion = backUnit(v.unitList,v.unitId)
        this.tableData.push(obj)
      });
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>